<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>{{action}} Data</b-card-title>
                <b-card-title class="text-primary">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        class="btn-icon"
                        size="sm"
                        :to="{name: 'mstDaerahKelurahan'}"
                    >
                        <feather-icon icon="ArrowLeftIcon" />
                    </b-button>
                </b-card-title>
            </b-card-header>

            <b-card-body>
                <b-form class="needs-validation" @submit.prevent="submitForm">
                    <b-form-row>
                        <b-col
                            md="10"
                            offset-md="1"
                        >
                            <!-- input label -->
                            <b-form-group
                                label="Label"
                                label-for="label"
                            >
                                <b-form-input
                                    id="label"
                                    v-model="label"
                                    :state="validateState(v$.label)"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.label.$errors" :key="index">
                                    {{ error.$validator == 'uniqueLabel' ? 'Label Telah Digunakan' : error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input provinsi -->
                            <b-form-group
                                label="provinsi"
                                label-for="provinsi"
                            >
                                <b-form-select
                                    v-model="v$.provinsi.$model"
                                    :options="dataProvinsi"
                                    :state="validateState(v$.provinsi)"
                                    @change="changeProvinsi"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.provinsi.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input kotaKabupaten -->
                            <b-form-group
                                label="Kota Kabupaten"
                                label-for="kotaKabupaten"
                                v-if="isShowKotaKabupaten"
                            >
                                <b-form-select
                                    v-model="v$.kotaKabupaten.$model"
                                    :options="dataKotaKabupaten"
                                    :state="validateState(v$.kotaKabupaten)"
                                    @change="changeKotaKabupaten"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.kotaKabupaten.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <!-- input kecamatan -->
                            <b-form-group
                                label="Kecamatan"
                                label-for="kecamatan"
                                v-if="isShowKecamatan"
                            >
                                <b-form-select
                                    v-model="v$.kecamatan.$model"
                                    :options="dataKecamatan"
                                    :state="validateState(v$.kecamatan)"
                                />
                                <b-form-invalid-feedback v-for="(error, index) of v$.kecamatan.$errors" :key="index">
                                    {{ error.$message }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-col
                                cols="12"
                                class="text-right pr-0"
                            >
                                <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    type="button"
                                    variant="outline-secondary"
                                    class="mr-1"
                                    @click="resetForm"
                                >
                                    Reset
                                </b-button>
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                                >
                                <div v-if="isSubmit">
                                    <b-spinner small />
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div v-else>Simpan</div>
                                </b-button>
                            </b-col>
                        </b-col>
                    </b-form-row>
                </b-form>
            </b-card-body>
        </b-card>
    </div>
  
</template>

<script>
import {
    BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect
} from 'bootstrap-vue'
import { required, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {ref, computed, watchEffect } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

export default {
    setup(props, context){
        const g = context.root

        var isSubmit = ref(false)
        const router = g.$router
        const $http = g.$http

        //form name
        var label = ref('')
        var provinsi = ref('')
        var kecamatan = ref('')
        var kotaKabupaten = ref('')

        var dataProvinsi = ref([])
        var dataKotaKabupaten = ref([])
        var dataKecamatan = ref([])

        var isShowKotaKabupaten = ref(false)
        var isShowKecamatan = ref(false)

        watchEffect(() => {
            label.value = props.labelVal
            provinsi.value = props.provinsiVal
            kotaKabupaten.value = props.kotaKabupatenVal
            kecamatan.value = props.kecamatanVal
            dataProvinsi.value = props.dataProvinsiVal
            dataKotaKabupaten.value = props.dataKotaKabupatenVal
            dataKecamatan.value = props.dataKecamatanVal
            isShowKotaKabupaten.value = props.isShowKotaKabupatenVal
            isShowKecamatan.value = props.isShowKecamatanVal
        })

        // validation
        const requiredMsg = 'tidak boleh kosong'
        const $externalResults = ref({})
        const rules = computed(() => ({
            label: { 
                required: helpers.withMessage('Label '+requiredMsg, required)
            },
            provinsi: { 
                required: helpers.withMessage('Provinsi '+requiredMsg, required)
            },
            kotaKabupaten: { 
                required: helpers.withMessage('Kota / Kabupaten '+requiredMsg, required)
            },
            kecamatan: { 
                required: helpers.withMessage('Kecamatan '+requiredMsg, required)
            },
        }))

        const v$ = useVuelidate(rules, { label, provinsi, kotaKabupaten, kecamatan }, {$externalResults, $autoDirty: true})

        const submitForm = async () => {
            isSubmit.value = true
            const isFormCorrect = await v$.value.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            //jika error validasi
            if (!isFormCorrect) {
                isSubmit.value = false
                return
            }

            // mapping data
            let data = {
                label: label.value,
                kecamatan: kecamatan.value,
                id: props.id
            };

            $http({
                url: '/mst/daerah/kelurahan/submit/'+props.action,
                data,
                method: 'post',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    router.push({name: 'mstDaerahKelurahan'})
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const changeProvinsi = () => {
            if(!provinsi.value)
                kotaKabupaten.value = null

            $http({
                url: '/mst/daerah/kota-kabupaten/get-data-kota-kabupaten',
                params: {
                    provinsi: provinsi.value,
                    kotaKabupaten: kotaKabupaten.value,
                    halaman: 'form'
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {dataKotaKabupatenVal, kotaKabupatenVal} = res.data.data
                    dataKotaKabupaten.value = dataKotaKabupatenVal
                    kotaKabupaten.value = kotaKabupatenVal

                    if(!kotaKabupaten.value)
                        isShowKecamatan.value = false

                    isShowKotaKabupaten.value = provinsi.value ? true : false
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Kota / Kabupaten Table`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const changeKotaKabupaten = () => {
            if(!provinsi.value)
                kotaKabupaten.value = kecamatan.value = ''
            else if(!kotaKabupaten.value)
                kecamatan.value = ''

            $http({
                url: '/mst/daerah/kecamatan/get-data-kecamatan',
                params: {
                    kotaKabupaten: kotaKabupaten.value,
                    kecamatan: kecamatan.value
                },
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {dataKecamatanVal, kecamatanVal} = res.data.data
                    dataKecamatan.value = dataKecamatanVal
                    kecamatan.value = kecamatanVal

                    isShowKecamatan.value = kotaKabupaten.value ? true : false
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Load Table`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const validateState = (item) => {
            const { $dirty, $error } = item
            return !$dirty ? null : $error ? false : null
        }

        const resetForm = () => {
            label.value = ''
        }

        return {
            v$, $externalResults, isSubmit, isShowKotaKabupaten, isShowKecamatan,
            label, dataProvinsi, provinsi, dataKotaKabupaten, kotaKabupaten, kecamatan, dataKecamatan,
            resetForm, validateState, submitForm, changeProvinsi, changeKotaKabupaten
        }
    },
    directives: {
        Ripple,
    },
    components: {
        BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect
    },
    props: {
        action: String,
        labelVal: String,
        dataProvinsiVal: Array,
        dataKotaKabupatenVal: Array,
        dataKecamatanVal: Array,
        provinsiVal: String,
        kotaKabupatenVal: String,
        kecamatanVal: String,
        isShowKotaKabupatenVal: Boolean,
        isShowKecamatanVal: Boolean,
        id: String
    },
}
</script>

<style lang="scss" scope>
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>